import { lazy } from 'react';

import { NoMatch } from 'shared';
const Home = lazy(() => import('../pages/Home'));
const SubsidiaryCompanies = lazy(() => import('../pages/SubsidiaryCompanies'));
const PresenceInTheWorld = lazy(() => import('../pages/PresenceInTheWorld'));
const Values = lazy(() => import('../pages/Values'));
const VisionAndMission = lazy(() => import('../pages/VisionAndMission'));
const SummaryData = lazy(() => import('../pages/SummaryData'));
const CommitmentAndSustainability = lazy(() =>
  import('../pages/CommitmentAndSustainability'),
);
const Contacts = lazy(() => import('../pages/Contacts'));
const Pages = lazy(() => import('../pages/_pages'));

export const routes = [
  { exact: true, path: '/', component: <Home /> },
  { exact: true, path: '/group', component: <Home /> },
  { exact: true, path: '/index.html', component: <Home /> },
  {
    exact: true,
    path: '/subsidiary-companies',
    component: <SubsidiaryCompanies />,
  },
  {
    exact: true,
    path: '/presence-in-the-world',
    component: <PresenceInTheWorld />,
  },
  { exact: true, path: '/values', component: <Values /> },
  { exact: true, path: '/vision-and-mission', component: <VisionAndMission /> },
  { exact: true, path: '/summary-data', component: <SummaryData /> },
  {
    exact: true,
    path: '/commitment-and-sustainability',
    component: <CommitmentAndSustainability />,
  },
  { exact: true, path: '/contacts', component: <Contacts /> },

  { exact: true, path: '/_pages', component: <Pages /> },

  { exact: false, path: '*', component: <NoMatch /> },
];
