export const menuData = ({ t }) => {
  return [
    {
      exact: true,
      to: '/',
      labelLink: t('menu.group'),
    },
    {
      exact: false,
      to: '/subsidiary-companies',
      labelLink: t('menu.subsidiary-companies'),
    },
    {
      exact: false,
      to: '/presence-in-the-world',
      labelLink: t('menu.presence-in-the-world'),
    },
    {
      exact: false,
      to: '/values',
      labelLink: t('menu.values'),
    },
    {
      exact: false,
      to: '/vision-and-mission',
      labelLink: t('menu.vision-and-mission'),
    },
    {
      exact: false,
      to: '/summary-data',
      labelLink: t('menu.summary-data'),
    },
    {
      exact: false,
      to: '/commitment-and-sustainability',
      labelLink: t('menu.commitment-and-sustainability'),
    },
    {
      exact: false,
      to: '/contacts',
      labelLink: t('menu.contacts'),
    },
  ];
};
