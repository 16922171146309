import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';

import { Header, Loader, Footer } from 'shared';

import { routes } from './routes';

import { menuMobileData } from 'utils/menuMobileData';
import { menuData } from 'utils/menuData';
import { siteMapData } from 'utils/siteMapData';

import logo from 'assets/logos/logo.svg';
import logoSmall from 'assets/logos/logo.svg';

const headerProps = {
  menuData,
  menuMobileData,
  logo,
  logoSmall,
};

function App() {
  return (
    <Box className="App" style={{overflowX:'hidden'}}>
      <CssBaseline></CssBaseline>
      <Router>
        <Header {...headerProps} />
        <Suspense fallback={<Loader />}>
          <Switch>
            {routes.map((curr, idx) => (
              <Route key={idx} exact={curr.exact} path={curr.path}>
                {curr.component}
              </Route>
            ))}
          </Switch>
        </Suspense>
        <Footer logo={logo} logoSmall={logoSmall} siteMapData={siteMapData} />
      </Router>
    </Box>
  );
}

export default App;
