export const siteMapData = ({ t }) => [
  {
    name: 'Fincantieri Infrastructure S.p.A.',
    office1: `Sede Legale: Via Genova 1 - 34121 Trieste - Italy`,
    office2: `Sede Operativa: Via del Lavoro, 1 - 37067 Valeggio sul Mincio (VR) - Italy`,
    register:'', 
    legal: `
    Registro delle Imprese Venezia Giulia<br />
    C.F. e n. iscr.  01294560329<br />
    P.IVA 01294560329<br />
    N. R.E.A.   TS - 200879<br />`,
    cap: `Capitale Sociale € 500.000,00`,
    soc: `Società soggetta a direzione e coordinamento di Fincantieri  S.p.A.<br />
    Società con socio unico `,
    contacts: `
    CONTATTI<br />
    <a href="mailto:info@fincantieri-infrastructure.it">info@fincantieri-infrastructure.it</a><br />
    <a href="https://www.fincantieri-infrastructure.com">www.fincantieri-infrastructure.com</a>
    `,
  },
  {
    name: 'Fincantieri Infrastructure Opere Marittime S.p.A.',
    office1: `Sede Legale: Via Genova 1 - 34121 Trieste - Italy`,
    office2: `Sede Operativa: Via Carciano 69 - 00131 Roma - Italy`,
    register: '',
    legal: 
    `Registro delle Imprese Venezia Giulia<br />
    C.F. e n. iscr. 01336990328<br />
    P.IVA 01336990328<br />
    N. R.E.A.   TS - 205855<br />`,
    cap: `Capitale Sociale  € 100.000,00`,
    soc: `Società soggetta a direzione e coordinamento di Fincantieri Infrastructure S.p.A.<br />
    Società con socio unico `,
    contacts: `
    CONTATTI<br />
    <a href="mailto:segreteria-fiom@fincantieri-Infrastructure.it">segreteria-fiom@fincantieri-Infrastructure.it</a><br />
    <a href="https://www.fincantieri-infrastructure-operemarittime.com">www.fincantieri-infrastructure-operemarittime.com</a>
    `,
  },
  {
    name: 'FINSO S.p.A.',
    office1:
      `Sede Legale: Via G. del Pian dei Carpini 1 - 50127 Firenze - Italy`,
    office2: `Sede Operativa: Via G. del Pian dei Carpini 1 - 50127 Firenze - Italy`,
    register: '',
    legal: `Registro delle Imprese di Firenze<br />
    C.F. e n. iscr. 15952111001<br />
    P.IVA 15952111001<br />
    N. R.E.A.  FI - 679859<br />`,
    cap:`
    Capitale Sociale i.v. € 20.000.000,00`,
    soc: `Società soggetta a direzione e coordinamento di Fincantieri Infrastructure S.p.A.`,
    contacts: `
    CONTATTI<br />
    <a href="mailto:info@finso.it">info@finso.it</a><br />
    <a href="https://www.finso.it">www.finso.it</a>
    `,
  },
  {
    name: 'SOF S.P.A.',
    office1:
      `Sede Legale: Via G. del Pian dei Carpini 1 - 50127 Firenze - Italy`,
    office2: `Sede Operativa: Via G. del Pian dei Carpini 1 - 50127 Firenze - Italy`,
    register: '',
    legal: `Registro delle Imprese di Firenze<br />
    C.F. e P.IVA  05481180486<br />
    N. R.E.A. FI - 550126<br />`,
    cap: `
    Capitale Sociale i.v. € 5.000.000,00`,
    soc: `Società soggetta al coordinamento di Fincantieri Infrastrutture Sociali S.p.A.<br />
    Società con socio Unico`,
    contacts: `
    CONTATTI<br />
    <a href="mailtosegreteria@sof.it">segreteria@sof.it</a><br />
    <a href="https://www.sof.it">www.sof.it</a>
    `,
  },
];
